import React, { useState } from 'react';
import './App.css';

function App() {
    const [walletAddress, setWalletAddress] = useState('');
    const [result, setResult] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const guaranteedList = await fetchCSV('/wallets/GTD.csv');
            const fcfsList = await fetchCSV('/wallets/FCFS.csv');

            const inGuaranteed = guaranteedList.includes(walletAddress.toLowerCase());
            const inFCFS = fcfsList.includes(walletAddress.toLowerCase());

            if (inGuaranteed && inFCFS) {
                setResult('Your wallet address is in both the <strong>Guaranteed Spot</strong> and <strong>First Come First Serve</strong> lists.');
            } else if (inGuaranteed) {
                setResult('Your wallet address is in the <strong>Guaranteed Spot</strong> list.');
            } else if (inFCFS) {
                setResult('Your wallet address is in the <strong>First Come First Serve</strong> list.');
            } else {
                setResult('Your wallet address is <strong>not eligible</strong>.');
            }
        } catch (error) {
            setResult('There was an error processing your request.');
            console.error('Error:', error);
        }
    };

    const fetchCSV = async (filePath) => {
        const response = await fetch(filePath);
        const data = await response.text();
        return parseCSV(data);
    };

    const parseCSV = (data) => {
        const parsedData = data.split('\n').map(row => row.trim().toLowerCase()).filter(row => row.length > 0);
        return parsedData;
    };

    return (
        <div className="container">
            <h1 className="custom-css-heading">Wallet Checker</h1>
            <form id="walletForm" onSubmit={handleSubmit}>
                <label htmlFor="walletAddress">Enter your wallet address:</label>
                <input 
                    type="text" 
                    id="walletAddress" 
                    name="walletAddress" 
                    value={walletAddress}
                    onChange={(e) => setWalletAddress(e.target.value)}
                    required 
                />
                <button type="submit">Check</button>
            </form>
            <div id="result" className="result" dangerouslySetInnerHTML={{ __html: result }}></div>
        </div>
    );
}

export default App;
